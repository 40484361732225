/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Background, Loading, NButton, NSearch, NTitle } from '~/components';
import { useApi, usePlaces } from '~/hooks';
import { postAddress } from '~/services/adress';
import { getTripById } from '~/services/trips';
import { getDomain } from '~/utils';

import { Container } from './styles';

const Disembarkation = () => {
  const { request } = useApi();

  const navigate = useNavigate();

  const [trip, setTrip] = useState();
  const { tripId } = useParams();

  const { state } = useLocation();

  useEffect(() => {
    (async () => {
      const { data } = await getTripById(tripId);
      setTrip(data);
    })();
  }, []);

  const disembarkation = trip
    ? {
      id: trip?.route.landingDistrict.id,
      city: trip?.route.landingDistrict.city,
      name: trip?.route.landingDistrict.name,
    }
    : state?.disembarkation;
  const reversed = trip ? trip?.reversed : state?.reversed;
  const routeId = trip?.route.id || state?.routeId;
  const addressEmbarkation = trip
    ? {
      id: trip.passengers[0].boardingAddress.id,
      name: trip.passengers[0].boardingAddress.name,
      reference: trip.passengers[0].boardingAddress.reference,
      latitude: trip.passengers[0].boardingAddress.latitude,
      longitude: trip.passengers[0].boardingAddress.longitude,
    }
    : state?.addressEmbarkation;

  const { place, searchRef, mapRef, coordinates, isMapLoading } = usePlaces(
    disembarkation?.id,
    !!trip
  );

  const redirect = () => {
    if (!place) {
      return;
    }

    const data = {
      name: place,
      reference: 'Rua',
      latitude: coordinates.lat,
      longitude: coordinates.long,
    };

    request(
      () => postAddress(disembarkation.id, data),
      response => {
        navigate(`/${getDomain()}/horarios`, {
          state: {
            disembarkation,
            reversed,
            routeId,
            addressEmbarkation,
            addressLanding: { ...data, id: response.data.id },
            hasReturn: 'no_return',
            visibleIdPassenger: trip?.passengers[0]?.passenger?.id ?? null,
          },
        });
      }
    );
  };

  return (
    <Container>
      <Background />
      <div className="div_top">
        <NTitle
          label={trip ? 'Endereço de entrega' : 'Desembarque'}
          color="white"
          className="title"
          noBack={!!trip}
        />
      </div>

      <NSearch
        ref={searchRef}
        className="nsearch"
        placeholder="Digite o nome da rua:"
        microphone
      />
      <NButton
        className="butonConfir"
        label="Confirmar"
        disabled={!place}
        onClick={redirect}
      />
      <div ref={mapRef} className="map" />
      {isMapLoading && <Loading />}
    </Container>
  );
};

export default Disembarkation;
