import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Background, Loading, NButton, NSearch, NTitle } from '~/components';
import { useApi, useApiEffect, usePlaces } from '~/hooks';
import { postAddress } from '~/services/adress';
import { getGlobalRoute } from '~/services/routes';
import { getDomain } from '~/utils';
import { createTrip as createTripRequest } from '~/services/trips';

import { Container } from './styles';

const Embarkation = () => {
  const { request } = useApi();

  const navigate = useNavigate();

  const { state } = useLocation();

  const embarkation = state?.embarkation;
  const disembarkation = state?.disembarkation;
  const reversed = state?.reversed;
  const routeId = state?.routeId;
  const [globalRoute, setGlobalRoute] = useState();

  const { place, searchRef, mapRef, coordinates, isMapLoading } = usePlaces(
    embarkation?.id || globalRoute?.boardingDistrict.id,
    true
  );

  useApiEffect(getGlobalRoute, response => {
    setGlobalRoute(response.data.content[0]);
  });

  const createTrip = data => {
    request(
      () =>
        createTripRequest({
          boardingAddressId: data.id,
          hasReturn: 'no_return',
          landingAddressId: data.id,
          licensedCategoryId: 'ff9a64c7-56c9-4693-9c8e-fdb6e0ff401d',
          passengerId: state.passengerId,
          passengerKms: 1,
          paymentMethod: 0,
          reversed: false,
          routeId: routeId || globalRoute?.id,
          seats: 1,
        }),
      () => navigate(`/${getDomain()}/establishment`)
    );
  };

  const redirect = () => {
    if (!place) {
      return;
    }

    const data = {
      name: place,
      reference: 'Rua',
      latitude: coordinates.lat,
      longitude: coordinates.long,
    };

    request(
      () =>
        postAddress(embarkation?.id || globalRoute?.boardingDistrict.id, data),
      response => {
        if (!state.addressLanding) {
          if (state?.establishment) {
            createTrip(response.data);
            return;
          }
          navigate(`/${getDomain()}/desembarque`, {
            state: {
              disembarkation: disembarkation || globalRoute?.landingDistrict,
              reversed: reversed || false,
              routeId: routeId || globalRoute?.id,
              addressEmbarkation: { ...data, id: response.data.id },
            },
          });
        } else {
          navigate(`/${getDomain()}/horarios`, {
            state: {
              disembarkation: disembarkation || globalRoute?.landingDistrict,
              addressLanding: state.addressLanding,
              reversed: reversed || false,
              routeId: routeId || globalRoute?.id,
              addressEmbarkation: { ...data, id: response.data.id },
            },
          });
        }
      }
    );
  };

  return (
    <Container isMapLoading={isMapLoading}>
      <Background />
      <div className="div_top">
        <NTitle label="Embarque" color="white" className="title" />
      </div>

      <NSearch
        ref={searchRef}
        className="nsearch"
        placeholder="Digite o nome da rua:"
        microphone
      />

      <NButton
        className="butonConfir"
        label="Confirmar"
        disabled={!place}
        onClick={redirect}
      />
      <div ref={mapRef} className="map" />
      {isMapLoading && <Loading />}
    </Container>
  );
};

export default Embarkation;
