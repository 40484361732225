/* eslint-disable react/jsx-no-comment-textnodes */
import dayjs from 'dayjs';
import { ReactComponent as PinIcon } from '~/assets/icons/establishment_location.svg';
import { ReactComponent as Location } from '~/assets/icons/location.svg';
import { PriceFormatter } from '~/utils';

const { Container } = require('./styles');

const NCardEstablishment = ({
  driver,
  passenger,
  status,
  address,
  cost,
  date = new Date(),
}) => (
  <Container status={status}>
    <div className="es_card_header">
      <div className="es_title">
        {status !== 'running' && (
          <p>
            {status === 'finished'
              ? dayjs(date).format('DD/MM/YYYY HH:mm')
              : 'Cancelada'}
          </p>
        )}
        <p>{driver}</p>
      </div>
      <PinIcon />
    </div>
    <div className="es_card_divider" />
    <div className="es_card_body">
      <div>
        <div className="es_card_status" />
        <p>
          {passenger} - {PriceFormatter.format(cost)}
        </p>
      </div>
    </div>
    <div className="es_card_footer">
      <Location />
      <p>{address}</p>
    </div>
  </Container>
);

export default NCardEstablishment;
