/* eslint-disable prettier/prettier */

import React from 'react';

import timelineIcon from '~/assets/icons/timeline.svg';
import { AiOutlineSearch } from 'react-icons/ai';

import { Container } from './styles';
import NCardItem from '../NCardItem';

const NCardHistory = ({
  date,
  id,
  type,
  time,
  price,
  originNeighbour,
  destinyNeighbour,
  origin,
  destiny,
  driver,
  driverPhoto,
  rate,
  changeBoarding,
  changeLanding,
  goToTrip = () => null,
  pix,
  ...rest
}) => {
  const [isInverted, setIsInverted] = React.useState(false);

  return (
    <Container {...rest}>
      <div className="flex-row justify-between">
        <div className="flex-row">
          <img src={timelineIcon} alt="timeline-icon" className="timeline" />
          <div
            className="flex-col addresses"
            style={{
              flexDirection: isInverted ? 'column-reverse' : 'column',
            }}
          >
            {origin.length <= 100 && (
              <p className="origin-street">
                <AiOutlineSearch className="search-icon" size={18} />
                {origin}
              </p>
            )}
            {origin.length > 100 && (
              <p className="origin-street">
                <AiOutlineSearch className="search-icon" size={18} />
                {origin.slice(0, 100)}...
              </p>
            )}
            {destiny.length <= 100 && (
              <p className="destiny-street mt">
                <AiOutlineSearch className="search-icon" size={18} />
                {destiny}
              </p>
            )}
            {destiny.length > 100 && (
              <p className="destiny-street mt">
                <AiOutlineSearch className="search-icon" size={18} />
                {destiny.slice(0, 100)}...
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="sourceWrapper">
        <div className="sourceButtons">
          <button
            className="invert"
            type="button"
            onClick={() => {
              setIsInverted(prev => !prev);
            }}
          >
            <span>Inverter endereços</span>
          </button>
        </div>
      </div>

      <div className="item-div">
        {/* <NCardItem small label={driver} /> */}
        <NCardItem
          onClick={() => goToTrip(isInverted)}
          small
          primary
          label="Chamar"
        />
      </div>
    </Container>
  );
};

export default NCardHistory;
