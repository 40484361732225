/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from 'react';
import { ReactComponent as Location } from '~/assets/icons/location.svg';
import { useApi } from '~/hooks';
import { updateWaitingTrip } from '~/services/trips';
import { PriceFormatter } from '~/utils';
import { NButton } from '~/components';

const { Container } = require('./styles');

const NCardEstablishmentWaiting = ({
  driver,
  passenger,
  driverPhotoImage,
  status,
  address,
  cost,
  id,
  orderIsReady,
  handleReset,
  ...props
}) => {
  const { request } = useApi();
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    setLoading(true);

    return request(
      () => {
        updateWaitingTrip(id, { orderIsReady: true });
      },
      () => {
        setLoading(false);
        handleReset();
      }
    );
  };

  return (
    <Container status={status} {...props}>
      {/* Remover elementos sem gerar erros de ESLint */}
      <div className="es_card_body">
        <div>
          <div className="es_card_status" />
          <p>
            {passenger} - {PriceFormatter.format(cost)}
          </p>
        </div>
      </div>
      <div className="es_card_footer">
        <Location />
        <p>{address}</p>
      </div>
      <div className="es_card_footer_2">
        <NButton
          label={!orderIsReady ? 'Aguardando' : 'Buscando entregador...'}
          className="button"
          color="primary"
          type="button"
          disabled={loading || orderIsReady}
          onClick={handleUpdate}
        />
      </div>
    </Container>
  );
};

export default NCardEstablishmentWaiting;
