/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { BsCreditCardFill } from 'react-icons/bs';
import { HiArrowLeft } from 'react-icons/hi';
import { IoCashOutline } from 'react-icons/io5';
import { MdOutlinePix } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { NTimer } from '~/components';
import NWhiteCounter from '~/components/NWhiteCounter';
import { useAlerts, useApi, useApiEffect, useSocketContext } from '~/hooks';
import { api } from '~/services/api';
import { getSharedTrip, getTimes, getTripsPassenger } from '~/services/trips';
import { PriceFormatter } from '~/utils';
import { getCorrectTime } from '~/utils/correctTime';
import NModalHours from './NModalHours';
import { SharedTripsPage } from './SharedTripsPage';

import ConfirmTripModal from './ConfirmTripModal';
import {
  BackButton,
  Categories,
  Category,
  Container,
  Description,
  Header,
  PaymentOption,
  PaymentOptionSelected,
  Payments,
  Price,
  Title,
  Top,
} from './styles';

const BOARDING_RADIUS = 5000;
const LANDING_RADIUS = 10000;

const Times = () => {
  const [times, setTimes] = useState([]);
  const [trips, setTrips] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [category, setCategory] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [time, setTime] = useState('');
  const [trip, setTrip] = useState();
  const [spots, setSpots] = useState(1);
  const [modal, setModal] = useState(false);
  const [timePicker, setTimePicker] = useState(false);
  const [sharedTripsPage, setSharedTripsPage] = useState(false);
  const [kms, setKms] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tripIdJoin, setTripIdJoin] = useState(null);

  const { socket } = useSocketContext();
  const { request } = useApi();

  const { state } = useLocation();
  const { routeId, disembarkation } = state;

  const { newErrorAlert } = useAlerts();

  const navigate = useNavigate();

  function modalTimes(list) {
    let timesList;
    if (trips.length > 0)
      timesList = list.filter(timeI => {
        const hasSame = trips.filter(tripI => {
          const correctTime = `${getCorrectTime(tripI.time)}:00`;
          if (correctTime === timeI) return true;
          return undefined;
        });

        if (!hasSame.length > 0) return timeI;
        return undefined;
      });
    else timesList = list;

    return timesList;
  }

  function attData(data) {
    const t = data.filter(
      tr => tr.route.landingDistrict === disembarkation.name
    );
    setTrips(t);
  }

  function attTrips() {
    request(
      () => getTripsPassenger(routeId),
      response => attData(response.data)
    );
  }
  useApiEffect(
    () => getTripsPassenger(routeId),
    response => attData(response.data)
  );
  useApiEffect(
    () => getTimes(),
    response => {
      setTimes(response.data);
    }
  );

  useEffect(() => {
    function openModal() {
      setModal(true);
      setTime('');
      setTrip();
      setSpots(4);
    }

    if (category === 'shared') {
      setSpots(0);
    } else if (['private', 'motorbike'].includes(category)) {
      openModal();
    }
  }, [category]);

  const fetchTime = () => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${state.addressEmbarkation.longitude},${state.addressEmbarkation.latitude};${state.addressLanding.longitude},${state.addressLanding.latitude}?access_token=${process.env.REACT_APP_MAP_BOX_API_KEY}`;
    try {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setKms((data.routes[0].distance / 1000).toFixed(1));
        })
        .catch(err => {
          console.log(err);
        });
    } catch {
      console.log('axim');
    }
  };

  useEffect(() => {
    socket.on('update-available-trips', data => {
      if (data.route.id === routeId) attTrips();
    });

    api.get('/licensed-categories').then(res => {
      setCategoryList(res.data.content);
    });

    fetchTime();
  }, []);

  function createTrip() {
    if (spots > 4)
      newErrorAlert(
        'Não há assentos o suficiente, diminua a quantidade de vagas.'
      );
    else {
      const boardingAddressId = state.invertedManually
        ? state.addressLanding.id
        : state.addressEmbarkation.id;

      const landingAddressId = state.invertedManually
        ? state.addressEmbarkation.id
        : state.addressLanding.id;

      const body = {
        routeId: state.routeId,
        boardingAddressId,
        landingAddressId,
        boardingReference: state.addressEmbarkation.reference,
        landingReference: state.addressLanding.reference,
        reversed: state.reversed,
        time: `${time.length <= 5 ? `${time}:00` : time}`,
        seats: spots,
        type: categoryList[category].category.tripType,
        licensedCategoryId: categoryList[category].id,
        passengerKms: kms,
        hasReturn: state.hasReturn,
        paymentMethod,
        visibleIdPassenger: state.visibleIdPassenger,
      };

      socket.emit('create-trip', body);
    }
  }

  useEffect(() => {
    const body = {
      tripId: tripIdJoin,
      boardingAddressId: state.addressEmbarkation.id,
      landingAddressId: state.addressLanding.id,
      seats: spots,
      kms,
      paymentMethod,
    };

    if (body.tripId) socket.emit('join-trip', body);
  }, [tripIdJoin]);

  const handleJoinClick = async () => {
    const boardingLatitude = String(state.addressEmbarkation.latitude);
    const boardingLongitude = String(state.addressEmbarkation.longitude);
    const boardingRadius = BOARDING_RADIUS;
    const landingLatitude = String(state.addressLanding.latitude);
    const landingLongitude = String(state.addressLanding.longitude);
    const landingRadius = LANDING_RADIUS;
    const seats = spots;

    request(
      () =>
        getSharedTrip(
          boardingLatitude,
          boardingLongitude,
          boardingRadius,
          landingLatitude,
          landingLongitude,
          landingRadius,
          seats,
          `["${categoryList[category].category.name}"]`
        ),
      response => {
        if (response.length === 0) {
          createTrip();
        }
        if (response.length !== 0) {
          setTripIdJoin(response[0]);
        }

        setIsLoading(true);
      }
    );
  };

  function sendToWait() {
    if (time && spots) {
      if (trip) joinTrip();
      else createTrip();
    } else if (!time) newErrorAlert('Escolha um horário');
    else if (!spots) newErrorAlert('Indique a quantidade de vagas');
  }

  function chosed(actualTime) {
    let is;
    if (actualTime === time) {
      is = true;
    } else {
      is = false;
    }
    return is;
  }

  // eslint-disable-next-line no-unused-vars
  const timers = trips.map(item => (
    <NTimer
      key={item.id}
      label={`${getCorrectTime(item.time)} - ${item.cost.individual}`}
      num={item.passengers}
      onClick={() => {
        setTime(item.time);
        setTrip(item);
      }}
      chosed={chosed(item.time)}
      vagas={spots}
      className="timer"
    />
  ));

  function handleGetCurrentTime(date) {
    const timezone = date.getTimezoneOffset() / 60;
    if (timezone === 3) {
      const hours = date.getHours();
      const minutes = date.getMinutes() + 3;
      const currentTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}`;
      setTime(currentTime);
    } else if (timezone === 4) {
      const hours = date.getHours() + 1;
      const minutes = date.getMinutes() + 3;
      const currentTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}`;

      setTime(currentTime);
    } else if (timezone === 5) {
      const hours = date.getHours() + 2;
      const minutes = date.getMinutes() + 3;
      const currentTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}`;
      setTime(currentTime);
    } else if (timezone === 2) {
      const hours = date.getHours() - 1;
      const minutes = date.getMinutes() + 3;
      const currentTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}`;
      setTime(currentTime);
    }
  }

  useEffect(() => {
    if (category !== null) {
      if (categoryList[category].category.tripType === 'private') {
        handleGetCurrentTime(new Date());
        setTimePicker(true);
      } else if (categoryList[category].category.tripType === 'shared') {
        handleGetCurrentTime(new Date());
        setSharedTripsPage(true);
      }
    }
  }, [category]);

  const calculatePrice = (
    sharedTrip,
    kilometers,
    roofKms,
    privateCost,
    seats,
    individualCost,
    averageCost,
    fixedPrice,
    fixedPriceRoofKm,
    minimumCost
  ) => {
    let cost;

    if (sharedTrip) {
      if (kms > roofKms) {
        if (fixedPrice) {
          cost = privateCost * seats + individualCost;
        } else {
          cost = privateCost * kms * seats + individualCost;
        }
      } else if (fixedPrice && fixedPriceRoofKm) {
        cost = privateCost + averageCost * seats + individualCost;
      } else if (fixedPrice) {
        cost =
          privateCost + averageCost * (roofKms - kms) * seats + individualCost;
      } else if (fixedPriceRoofKm) {
        cost = privateCost * roofKms + averageCost * seats + individualCost;
      } else {
        cost =
          privateCost * roofKms +
          averageCost * (roofKms - kms) * seats +
          individualCost;
      }
    }

    if (!sharedTrip) {
      if (kms > roofKms) {
        if (fixedPrice) {
          cost = privateCost + individualCost;
        } else {
          cost = privateCost * kms + individualCost;
        }
      } else if (fixedPrice && fixedPriceRoofKm) {
        cost = privateCost + averageCost + individualCost;
      } else if (fixedPrice) {
        cost = privateCost + averageCost * (roofKms - kms) + individualCost;
      } else if (fixedPriceRoofKm) {
        cost = privateCost * roofKms + averageCost + individualCost;
      } else {
        cost =
          privateCost * roofKms +
          averageCost * (roofKms - kms) +
          individualCost;
      }
    }
    cost = Math.max(cost, minimumCost);

    return cost;
  };

  console.log(state);

  const CategoryList = categoryList.map((value, index) => {
    if (value.active === true) {
      return (
        <Category
          key={index}
          active={category === index}
          onClick={() => setCategory(index)}
        >
          <Top>
            <img
              src={`/images/categories/${value.category.icon}.svg`}
              alt="Icon"
            />
            <img src="/images/categories/verified.svg" alt="Verified" />
          </Top>
          <Title>{value.category.name}</Title>
          <Description>+ {value.category.info}</Description>
          {value.category.tripType === 'shared' && (
            <Price>
              {PriceFormatter.format(
                calculatePrice(
                  true,
                  Number(kms),
                  Number(value.roofKms),
                  Number(value.privateConst),
                  spots,
                  Number(value.cost),
                  Number(value.averageConst),
                  Number(value.fixedPrice),
                  Number(value.fixedPriceRoofKm),
                  Number(value.minimumCost)
                )
              )}
            </Price>
          )}
          {value.category.tripType === 'private' && (
            <Price>
              {PriceFormatter.format(
                calculatePrice(
                  false,
                  Number(kms),
                  Number(value.roofKms),
                  Number(value.privateConst),
                  spots,
                  Number(value.cost),
                  Number(value.averageConst),
                  Number(value.fixedPrice),
                  Number(value.fixedPriceRoofKm),
                  Number(value.minimumCost)
                )
              )}
            </Price>
          )}
        </Category>
      );
    }

    return <></>;
  });

  return (
    <Container>
      <Header>
        <BackButton onClick={() => navigate(-1)}>
          <HiArrowLeft size={24} color="white" />
        </BackButton>
      </Header>

      <NWhiteCounter
        label="Vagas"
        className="counter"
        value={spots}
        onChange={setSpots}
      />

      <Categories>{CategoryList}</Categories>

      <p className="label-payments">Formas de pagamento</p>
      <Payments>
        {paymentMethod === 1 ? (
          <PaymentOptionSelected onClick={() => setPaymentMethod(1)}>
            <IoCashOutline size={25} color="#22f100" />
            <span>Dinheiro</span>
          </PaymentOptionSelected>
        ) : (
          <PaymentOption onClick={() => setPaymentMethod(1)}>
            <IoCashOutline size={25} color="#22f100" />
            <span>Dinheiro</span>
          </PaymentOption>
        )}

        {paymentMethod === 2 ? (
          <PaymentOptionSelected onClick={() => setPaymentMethod(2)}>
            <MdOutlinePix size={25} color="#00BDAE" />
            <span>Pix</span>
          </PaymentOptionSelected>
        ) : (
          <PaymentOption onClick={() => setPaymentMethod(2)}>
            <MdOutlinePix size={25} color="#00BDAE" />
            <span>Pix</span>
          </PaymentOption>
        )}

        {paymentMethod === 3 ? (
          <PaymentOptionSelected onClick={() => setPaymentMethod(3)}>
            <BsCreditCardFill size={25} color="#000" />
            <span>Máquininha</span>
          </PaymentOptionSelected>
        ) : (
          <PaymentOption onClick={() => setPaymentMethod(3)}>
            <BsCreditCardFill size={25} color="#000" />
            <span>Máquininha</span>
          </PaymentOption>
        )}
      </Payments>

      {/*
       */}

      <SharedTripsPage
        active={sharedTripsPage}
        trips={trips}
        spots={spots}
        state={state}
        isLoading={isLoading}
        joinTrip={() => handleJoinClick()}
        createTrip={() => {
          createTrip();
        }}
        onClose={() => {
          setSharedTripsPage(false);
          setCategory(null);
        }}
        smallButtonAction={() => {
          setModal(true);
          setTime('');
          setTrip();
        }}
      />

      <NModalHours
        visible={modal}
        times={modalTimes(times)}
        onClose={() => {
          setModal(false);
        }}
        spots={spots}
        onTime={chosen => setTime(chosen)}
        time={time}
        onConfirm={() => {
          sendToWait();
          setModal(false);
        }}
      />

      <ConfirmTripModal
        active={timePicker}
        actualTime={time}
        onConfirm={() => {
          sendToWait();
          setTimePicker(false);
        }}
        onClose={() => {
          setTimePicker(false);
          setCategory(null);
        }}
      />
    </Container>
  );
};

export default Times;
