import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.styles.itemWidth}
  color: ${({ theme }) => theme.colors.gray};

  position: relative;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 0.86386px 3.45544px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  .icon {
    position: absolute;
    top: 35%;
    left: 20px;
  }

  .icon_plus {
    position: absolute;
    top: 30%;
    right: 20px;
  }

  .input {
    padding: 0px 0px 0px 60px;

    width: 100%;
    height: 69px;
    border-width: 0;
    border-radius: 20px;
    background: white;

    color: #000000;

    &::placeholder {
      opacity: 0.7;
    }
  }

  /* Estilos do microfone */
  .icon_microphone {
    position: absolute;
    top: 30%;
    right: 15px;
    background-color: #0000ff; /* Fundo branco atrás do microfone */
    border-radius: 50%; /* Tornando o fundo redondo */
    padding: 5px; /* Adiciona um padding para dar espaço ao redor do ícone */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra para destacá-lo */
    z-index: 1; /* Garante que o ícone fique em cima dos outros elementos */
    color: white;
  }

  .icon_microphone.recording {
    background-color: #ff0000; /* Cor de fundo quando estiver gravando */
  }
`;
