import React, { forwardRef, useState } from 'react';
import { AiOutlineSearch, AiOutlinePlus, AiOutlineAudio } from 'react-icons/ai';
import { Container } from './styles';

const NSearch = forwardRef(
  ({ className, plus, onPlus = () => null, microphone, ...rest }, ref) => {
    const [recording, setRecording] = useState(false);

    const startRecording = () => {
      if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
        alert('Seu navegador não suporta reconhecimento de voz.');
        return;
      }

      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;

      const recognition = new SpeechRecognition();
      recognition.lang = 'pt-BR'; // Define o idioma para português

      recognition.onstart = () => setRecording(true);
      recognition.onend = () => setRecording(false);

      recognition.onresult = event => {
        const [result] = event.results;
        const [item] = result;
        const { transcript } = item;

        if (ref && ref.current) {
          ref.current.value = transcript; // Preenche o campo de entrada com o texto reconhecido
        }
      };

      recognition.onerror = event => {
        console.error('Erro no reconhecimento de voz:', event.error);
        alert('Ocorreu um erro ao usar o reconhecimento de voz.');
        setRecording(false);
      };

      recognition.start();
    };

    return (
      <Container className={className}>
        <AiOutlineSearch size={24} className="icon" />
        <input className="input" {...rest} ref={ref} />
        {plus && (
          <AiOutlinePlus size={24} className="icon_plus" onClick={onPlus} />
        )}
        {microphone && (
          <AiOutlineAudio
            size={35}
            className={`icon_microphone ${recording ? 'recording' : ''}`}
            onClick={startRecording}
          />
        )}
      </Container>
    );
  }
);

export default NSearch;
